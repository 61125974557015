import { useEffect, useState } from "react";
import { ProductType, shuffle } from "../utilities";
import { useProduct } from "../hooks/useProduct";
import { ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import ProductCard from "../components/ProductCard";
import { RxCrossCircled } from "react-icons/rx";
import { IoMdArrowRoundBack } from "react-icons/io";

export const Products = () => {
  const [Query, setQuery] = useState(useLocation().state || {});

  const { isLoading, isError, data, hasNextPage, fetchNextPage, isFetching } =
    useProduct();

  const [products, setProducts] = useState<ProductType[]>([]);

  const navigate = useNavigate();

  const filter = () => {
    var filteredData = [];
    const allData: ProductType[] = [];

    data?.pages.map((group: any) => {
      group.data.results.map((product: ProductType) => {
        allData.push(product);
      });
    });

    if (Query.searchQuery && !Query.isSearch) {
      filteredData = allData?.filter((product: ProductType) =>
        product.category.includes(Query.searchQuery)
      );
    } else if (Query.searchQuery && Query.isSearch) {
      filteredData = allData?.filter(
        (product: ProductType) =>
          product.category.includes(Query.searchQuery) ||
          product.name?.includes(Query.searchQuery)
      );
    } else filteredData = allData;
    setProducts(filteredData);
  };

  useEffect(() => {
    filter();
  }, [Query.searchQuery, data]);

  if (isLoading) {
    return (
      <div className="bg-[#755138] min-h-screen flex justify-center items-center">
        <ImSpinner2 size={36} className="spinner" />
        <ScrollRestoration />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-screen flex justify-center bg-[#755138] items-center">
        Something went wrong
        <ScrollRestoration />
      </div>
    );
  }

  return (
    <div className="py-32 px-4 min-h-screen bg-[#755138] flex flex-col gap-y-4">
      {Query.searchQuery ? (
        <div className="bg-[#402c1f] rounded-xl flex w-56 self-center items-center justify-around p-2">
          <IoMdArrowRoundBack
            className="hover:cursor-pointer hover:text-[#e5d3b3] transition duration-200"
            onClick={() => navigate("/ProductsCategory")}
          />
          <div className="text-[#e5d3b3]">
            {Query.searchQuery.length > 15
              ? Query.searchQuery.substring(0, 15) + "..."
              : Query.searchQuery.substring(0, 15)}
          </div>
          <RxCrossCircled
            className="hover:cursor-pointer hover:text-[#e5d3b3] transition duration-200"
            onClick={() => {
              setQuery({ searchQuery: "", isSearch: false });
            }}
          />
        </div>
      ) : null}
      <div className="medium-product-boxes gap-20 mb-16 sm:px-10">
        {products?.map((product: ProductType, index: number) => {
          return <ProductCard key={index} product={product} />;
        })}
      </div>
      <div className="flex justify-center items-center">
        <button
          className={`green-button1 ${hasNextPage ? "" : "hidden"}`}
          onClick={() => fetchNextPage()}
        >
          {isFetching ? (
            <ImSpinner2 size={36} className="spinner" />
          ) : (
            "Load More"
          )}
        </button>
      </div>
      <ScrollRestoration />
    </div>
  );
};
