import { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";

const ScrollToTop = () => {
  const [displayButton, setDisplayButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 2000) setDisplayButton(true);
      else setDisplayButton(false);
    });
  }, []);

  return (
    <div
      className={`w-9 h-9 rounded-[50%] bg-[#30B474] ${
        displayButton ? "" : "hidden"
      } flex fixed justify-center items-center bottom-10 right-10 z-10 hover:cursor-pointer hover:scale-110 transition duration-200 border-solid border-2 border-[#6B4A33]`}
      onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
    >
      <IoIosArrowUp size={22} color="#6B4A33" />
    </div>
  );
};

export default ScrollToTop;
