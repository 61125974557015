import { PayPalButtons } from "@paypal/react-paypal-js";
import toast from "react-hot-toast";
import { useCartStore } from "../hooks/useCartStore";
import { ItemType, URL } from "../utilities";
import axios from "axios";
import { useAuthStore } from "../hooks/useAuthStore";
import { useNavigate } from "react-router-dom";

type PayPalProps = {
  totalPrice: string;
};

export const PayPal = ({ totalPrice }: PayPalProps) => {
  const { clearCart, cart } = useCartStore();
  const { user } = useAuthStore();

  const navigate = useNavigate();

  const storeOrder = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    var items = "";
    var quantity = "";

    for (var i = 0; i < cart.length; i++) {
      items += cart[i].id + ", ";
      quantity += cart[i].quantity.toString() + ", ";
    }

    const body = JSON.stringify({
      items: items,
      quantity: quantity,
      customer: user.id,
      status: "On It's Way",
      total_price: totalPrice,
    });

    try {
      await axios.post(`${URL}/order/api/`, body, config);
      navigate("/Account/OrderHistory");
    } catch (err) {
      alert(`Couldn't store order - ERorr - ${err}`);
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <PayPalButtons
        className="w-72 md:w-80"
        style={{
          layout: "vertical", // Try changing the layout
          color: "silver",
          shape: "pill",
          label: "paypal", // This should include the PayPal icon
        }}
        createOrder={(data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: totalPrice,
                },
              },
            ],
          });
        }}
        onApprove={(data: any, actions: any) => {
          return actions.order.capture().then((details: any) => {
            toast.success(
              `Transaction completed by ${details.payer.name.given_name}`
            );
            clearCart();
            storeOrder();
          });
        }}
      />
    </div>
  );
};
