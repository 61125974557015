import { Link } from "react-router-dom";
import { ProductType } from "../utilities";

export const HomeProductCard = ({ product }: { product: ProductType }) => {
  return (
    <Link to={`/Products/${product.id}`}>
      <div
        className={
          "h-[28vh] w-[21vh] bg-[#a3896c] hover:cursor-pointer overflow-hidden flex flex-col align-center medium-proudct-card rounded hover:scale-105 transition duration-300 aspect-[3/4]"
        }
      >
        <img
          src={product.image1}
          className="w-full h-[80%] md:h-[85%] object-cover"
          loading="lazy"
        />
        <div className="px-2 h-[20%] md:h-[15%] flex flex-col justify-center">
          <p className="text-md text-white">Price: ${product.price}</p>
          <p className={`text-md text-white${product.name ? "" : "hidden"}`}>
            {product.name}
          </p>
        </div>
      </div>
    </Link>
  );
};
