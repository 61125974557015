import { create } from "zustand";
import { UserType } from "../utilities";
import { persist } from "zustand/middleware";

type AuthType = {
  user: UserType;
  setUser: (user: UserType) => void;
};

export const useAuthStore = create<AuthType>()(
  persist(
    (set) => ({
      user: { name: "", email: "", id: null, phone_number: "", profile: "" },
      setUser(user) {
        set(() => ({ user: user }));
      },
    }),
    {
      name: "authentication",
      getStorage: () => localStorage,
    }
  )
);
