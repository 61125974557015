import { GiPresent } from "react-icons/gi";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { HiTruck } from "react-icons/hi2";
import { Link } from "react-router-dom";

type OrderHistoryCardProps = {
  order_code: string;
  order_date: string;
  status: "On It's Way" | "Delivered";
};

export const OrderHistoryCard = ({
  order_code,
  order_date,
  status,
}: OrderHistoryCardProps) => {
  return (
    <Link
      to={`/Account/OrderHistory/${order_code}`}
      className="w-72 md:w-[500px] h-28 md:h-20 bg-[#38271a] rounded-2xl flex justify-around items-center hover:scale-110 transition duration-300"
    >
      <div className="text-[#e5d3b3] flex md:justfiy-center justify-start">
        {status === "Delivered" ? (
          <div className="flex flex-col justify-center items-center">
            <GiPresent className="nav-icons" />
          </div>
        ) : (
          <div className="flex flex-col items-start">
            <HiTruck className="nav-icons truck" />
            {/* <span className="nav-icons-text">{status}</span> */}
          </div>
        )}
      </div>

      <div className="flex flex-col md:flex-row gap-4 items-center">
        <div className="text-[#e5d3b3]">{order_date.substring(0, 10)}</div>
      </div>

      <div>
        <MdOutlineArrowForwardIos size={24} color="#e5d3b3" />
      </div>
    </Link>
  );
};
