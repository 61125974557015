import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { URL } from "../utilities";

type useProductDetailProps = {
  queryKey: string[];
  id: string;
};

export const useProductDetail = ({ queryKey, id }: useProductDetailProps) => {
  const fetchProduct = () => {
    return axios.get(`${URL}/product/api/${id}`);
  };

  return useQuery({
    queryKey: queryKey,
    queryFn: fetchProduct,
    select: (data) => data.data,
  });
};
