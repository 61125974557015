import { useState } from "react";
import { useAuthStore } from "../hooks/useAuthStore";
import { ScrollRestoration } from "react-router-dom";

export const Profile = () => {
  const { user } = useAuthStore();
  const [openModal, setOpenModal] = useState(false);

  const deleteAccount = () => {
    setOpenModal(false);
  };

  return (
    <div className="py-48 bg-[#755138] min-h-screen flex flex-col items-center justify-center gap-y-8 p-4">
      <div className="flex justify-center items-center">
        <h1>Your Profile</h1>
      </div>
      <div className="flex justify-around w-full md:w-96  border-solid border-[#edfad4] border-2 rounded">
        <div className="flex flex-col gap-y-5 border-solid border-[#edfad4] border-r-2 p-2 md:p-4">
          <h2>Full Name</h2>
          <h2>Email</h2>
          <h2>Phone Number</h2>
        </div>
        <div className="flex flex-col gap-y-5 p-2 md:p-4">
          <h2>{user.name}</h2>
          <h2>{user.email}</h2>
          <h2>{user.phone_number}</h2>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div
          className={`w-80 aspect-video fixed bg-[#402c1f] flex flex-col items-center justify-between p-5 ${
            openModal ? "opacity-100" : "opacity-0 -z-20"
          } transition duration-300 top-[50%] modal rounded`}
        >
          <h1 className="text-white font-bold text-lg text-center">
            Are You Sure You Want to Delete Your Account?
          </h1>
          <div className="flex">
            <button
              className="red-button"
              disabled={!openModal}
              onClick={deleteAccount}
            >
              Delete
            </button>
            <button
              className="green-button1"
              disabled={!openModal}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
        <button className="red-button" onClick={() => setOpenModal(true)}>
          Delete Account
        </button>
      </div>
      <ScrollRestoration />
    </div>
  );
};
