import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import { Main } from "./components/Main";
import { Home } from "./pages/Home";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Products } from "./pages/Products";
import { Contact } from "./pages/Contact";
import { About } from "./pages/About";
import { Toaster } from "react-hot-toast";
import { ProductDetail } from "./pages/ProductDetail";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Category } from "./pages/Category";
import { Cart } from "./pages/Cart";
import { Account } from "./pages/Account";
import { SignUpForm } from "./pages/SignUpForm";
import { LoginForm } from "./pages/LoginForm";
import { ResetPassword } from "./pages/ResetPassword";
import { ResetPasswordConfirm } from "./pages/ResetPasswordConfirm";
import { AccounActivation } from "./pages/AccountActivation";
import { OrderHistory } from "./pages/OrderHisotry";
import { OrderHistoryDetail } from "./pages/OrderHistoryDetail";
import { Profile } from "./pages/Profile";

function App() {
  const queryClient = new QueryClient();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Main />}>
        <Route index element={<Home />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Products/:id" element={<ProductDetail />} />
        <Route path="/ProductsCategory" element={<Category />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Account" element={<Account />} />
        <Route path="/Account/OrderHistory" element={<OrderHistory />} />
        <Route
          path="/Account/OrderHistory/:order_code"
          element={<OrderHistoryDetail />}
        />
        <Route path="/Account/SignUp" element={<SignUpForm />} />
        <Route
          path="/Account/Activate/:uid/:token"
          element={<AccounActivation />}
        />
        <Route path="/Account/Login" element={<LoginForm />} />
        <Route path="/Account/ResetPassword" element={<ResetPassword />} />
        <Route
          path="/Account/ResetPassword/Confirm/:uid/:token"
          element={<ResetPasswordConfirm />}
        />
        <Route path="/Account/Profile" element={<Profile />} />
      </Route>
    )
  );

  const initialOptions = {
    clientId:
      "ARrdOY5dXrNnm0DdGApW3JcAopBdsRtFJpgusdN7vAv7grWJtvnp5Fs8dIZjaTQKJW8gBOQtEY3rM9Ej",
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-center" />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </PayPalScriptProvider>
  );
}

export default App;
