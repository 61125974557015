import { ImSpinner2 } from "react-icons/im";
import { useProduct } from "../hooks/useProduct";
import { ProductType } from "../utilities";
import ProductCard from "./ProductCard";
import { HomeProductCard } from "./HomeProductCard";

type RecommendedProps = {
  category: string;
  image: string;
};

export const Recommended = ({ category, image }: RecommendedProps) => {
  const { isLoading, isError, data } = useProduct();

  const filter = () => {
    const allData: ProductType[] = [];

    data?.pages.map((group: any) => {
      group.data.results.map((product: ProductType) => {
        allData.push(product);
      });
    });

    return allData?.filter(
      (product: ProductType) =>
        product.category.toLowerCase().includes(category.toLowerCase()) &&
        product.image1 !== image
    );
  };

  return (
    <div className="py-20 gap-y-20 flex flex-col justify-start items-center">
      <h1 className="md:text-4xl text-3xl font-bold">Similar Products</h1>
      <div className="medium-product-boxes px-10 md:w-full">
        {isLoading ? (
          <ImSpinner2 size={36} className="spinner" />
        ) : isError ? (
          <p>Something Went Wrong</p>
        ) : filter()?.length === 0 ? (
          <div className="flex items-center h-full">
            <p>Not Found!</p>
          </div>
        ) : (
          filter().map((product: ProductType, index: number) => {
            if (index > 3) return;
            return <ProductCard product={product} key={index} />;
          })
        )}
      </div>
    </div>
  );
};
