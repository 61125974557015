import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { URL } from "../utilities";

export const useProduct = () => {
  const fetchProduct = async (pageParam: number) => {
    return await axios.get(`${URL}/product/api/?page=${pageParam}`);
  };

  return useInfiniteQuery({
    queryKey: ["products"],
    queryFn: ({ pageParam = 1 }) => fetchProduct(pageParam),
    getNextPageParam: (lastPage: any, pages) => {
      return parseInt(lastPage?.data?.next?.slice(-1))
        ? parseInt(lastPage?.data?.next?.slice(-1))
        : null;
    },
    initialPageParam: 1,
    select: (data) => data,
  });
};
