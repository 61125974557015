import "../static/css/Pages/home.css";
import img from "../static/images/EnhancedLogo.png";

const LandingPage = () => {
  return (
    <div className="relative flex justify-center overflow-hidden">
      <div className="w-screen h-[80vh] flex justify-center items-center main"></div>
      <div className="w-full absolute h-full flex justify-center items-center layer"></div>
      <div className="absolute h-[70vh] flex items-center mt-10">
        <div className="blob-effect w-[200px] h-[200px] md:w-[380px] md:h-[380px]">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div className="flex flex-col p-5 gap-y-2 home-text w-80 absolute items-center">
            <div>
              <img src={img} className="sm:h-48 h-32" id="home-logo" />
            </div>
            <div className="text-start pl-10 pb-5 hidden md:flex">
              <p className="text-sm sm:text-lg">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est
                veniam explicabo tempora.
              </p>
            </div>
          </div>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="mt-48 z-10 absolute bottom-[-2px]"
      >
        <path
          fill="#755138"
          fill-opacity="1"
          d="M0,128L48,133.3C96,139,192,149,288,176C384,203,480,245,576,272C672,299,768,309,864,309.3C960,309,1056,299,1152,256C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default LandingPage;
