import toast from "react-hot-toast";
import {
  FaTelegram,
  FaInstagram,
  FaPinterest,
  FaEnvelope,
  FaFacebook,
  FaPhone,
  FaTiktok,
} from "react-icons/fa6";
import Map from "../static/images/AAMap.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied To Clipboard!", {
      duration: 6000,
    });
  };

  return (
    <div>
      <div className="bg-[#402c1f] min-h-[calc(100vh-130px)] md:min-h-80 md:h-auto sm:h-[calc(100vh-130px)] py-10 flex flex-col justify-around gap-y-8">
        <div className="boxes gap-12">
          <div className="flex flex-col items-center sm:gap-y-8 gap-y-4">
            <h1 className="text-3xl sm:text-4xl text-[#E5D3B3] font-bold">
              Location
            </h1>
            <a
              href="https://www.google.com/maps/place/Ke'a-Tibebat+P.L.C/@9.0026051,38.7883699,21z/data=!4m14!1m7!3m6!1s0x164b850023d687ef:0x10fad318969fdebd!2sKe'a-Tibebat+P.L.C!8m2!3d9.0026228!4d38.788498!16s%2Fg%2F11lf2z3t8p!3m5!1s0x164b850023d687ef:0x10fad318969fdebd!8m2!3d9.0026228!4d38.788498!16s%2Fg%2F11lf2z3t8p?authuser=2&entry=ttu"
              target="_blank"
              className="flex flex-col justify-center items-center gap-y-2"
            >
              <div className="w-28 h-28 bg-black rounded-[50%] hover:cursor-pointer object-cover overflow-hidden flex justify-center items-center">
                <img
                  src={Map}
                  className="hover:scale-150 transition duration-300"
                />
              </div>
              <p className="font-bold text-[#E5D3B3]">Ethiopia - A.A</p>
            </a>
          </div>
          <div className="flex flex-col items-center sm:gap-y-8 gap-y-4">
            <h1 className="text-3xl sm:text-4xl text-[#E5D3B3] font-bold">
              Get In Touch
            </h1>
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-4 hover:cursor-pointer">
                <FaEnvelope color="#E5D3B3" size={30} />
                <a
                  className="text-[#E5D3B3]"
                  href="mailto:keatibebat@gmail.com"
                >
                  keatibebat@gmail.com
                </a>
              </div>
              <div className="flex items-center gap-x-4  hover:cursor-pointer">
                <FaPhone color="#E5D3B3" size={30} />
                <a className="text-[#E5D3B3]" href="tel:+251904094047">
                  +251 904 094047
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex justify-center items-center gap-5 md:gap-8">
            <a href="https://t.me/keatibebat" target="_blank">
              <FaTelegram
                className="hover:cursor-pointer hover:scale-125 transition"
                color="#E5D3B3"
                size={32}
              />
            </a>
            <a
              href="https://www.instagram.com/keatibebat?igsh=MTh4cXg1ejdsOWM3OQ%3D%3D&utm_source=qr"
              target="_blank"
            >
              <FaInstagram
                className="hover:cursor-pointer hover:scale-125 transition"
                color="#E5D3B3"
                size={32}
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61562839007899&mibextid=LQQJ4d"
              target="_blank"
            >
              <FaFacebook
                className="hover:cursor-pointer hover:scale-125 transition"
                color="#E5D3B3"
                size={32}
              />
            </a>
            <a href="https://www.pinterest.com/keatibebat/" target="_blank">
              <FaPinterest
                className="hover:cursor-pointer hover:scale-125 transition"
                color="#E5D3B3"
                size={32}
              />
            </a>
            <a
              href="https://www.tiktok.com/@keatibebat?lang=en"
              target="_blank"
            >
              <FaTiktok
                className="hover:cursor-pointer hover:scale-125 transition"
                color="#E5D3B3"
                size={32}
              />
            </a>
          </div>
          <div className="flex justify-center items-center">
            <Link
              to={"/PrivacyPolicy"}
              className="text-[#E5D3B3] underline hover:cursor-Linkointer"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-16 bg-[#36251a] flex justify-center items-center">
        <p className="text-[#E5D3B3]">Copyright© 2024 Ke'a-Tibebat</p>
      </div>
    </div>
  );
};

export default Footer;
