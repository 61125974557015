import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../utilities";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { ImSpinner2 } from "react-icons/im";

export const AccounActivation = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    activateAccount();
  });

  const activateAccount = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      uid: uid,
      token: token,
    });

    try {
      await axios.post(`${URL}/account/auth/users/activation/`, body, config);
      toast.success("Account has been Activated");
      navigate("/Account/Login");
    } catch {}
  };

  return (
    <div className="py-32 bg-[#75975e] min-h-screen flex flex-col justify-center items-center">
      <ImSpinner2 size={36} className="spinner" />
    </div>
  );
};
