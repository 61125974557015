import { useParams } from "react-router-dom";
import { useOrder } from "../hooks/useOrder";
import { ItemHistoryCard } from "../components/ItemHistoryCard";

export const OrderHistoryDetail = () => {
  const { order_code } = useParams();

  const { isLoading, isError, data } = useOrder({
    queryKey: ["order_detail", order_code ? order_code : ""],
    id: order_code,
  });

  const itemList = data?.items.split(",");
  const quantityList = data?.quantity.split(",");

  return (
    <div className="py-48 px-5 bg-[#755138] min-h-screen flex flex-col items-center justify-center gap-y-8">
      {isLoading ? (
        "Loading"
      ) : isError ? (
        "Something Went Wrong"
      ) : (
        <div className="flex flex-col gap-1">
          <div className="p-5 bg-[#D7E8B6] rounded-t-xl">
            <h1 className="font-bold">Order Code: {data?.order_code}</h1>
          </div>
          <div className="p-5 bg-[#D7E8B6] rounded-b-xl">
            <h1 className="font-bold">
              Order Date: {data?.order_date.substring(0, 10)}
            </h1>
          </div>

          <div className="flex flex-col w-full gap-2 py-5 bg-[#D7E8B6] rounded-xl">
            <div className="flex justify-around">
              <h1 className="w-20 font-bold">ITEMS</h1>
              <h1 className="w-20 font-bold">QUANTITIY</h1>
            </div>
            <div className="flex flex-col">
              {itemList.map((item: string, index: number) => {
                return (
                  <ItemHistoryCard
                    item={item}
                    quantity={quantityList[index]}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          <div className="p-5 bg-[#D7E8B6] rounded-xl flex justify-around items-center">
            <h1 className="font-bold">{data?.status}</h1>
            <h1 className="font-bold">${data?.total_price}</h1>
          </div>
        </div>
      )}
    </div>
  );
};
