import { useState } from "react";
import toast from "react-hot-toast";
import emailjs from "emailjs-com";
import { ImSpinner2 } from "react-icons/im";
import { ScrollRestoration } from "react-router-dom";

export const Contact = () => {
  const [sendingMail, setSendingMail] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    setSendingMail(true);
    e.preventDefault();
    try {
      await emailjs.send(
        "service_79vbcw8",
        "template_r7g7vmw",
        {
          to_name: "Ke'a-Tibebat",
          from_name: formData.fullName,
          subject: formData.subject,
          message: formData.message,
        },
        "F-ZszC5gdwZIKlDHJ"
      );
      toast.success("Email Successfully Sent", {
        duration: 6000,
      });
      setSendingMail(false);
    } catch (error) {
      toast.error("Something went wrong", {
        duration: 6000,
      });
      setSendingMail(false);
    }
    setFormData({ fullName: "", email: "", subject: "", message: "" });
  };

  if (sendingMail) {
    return (
      <div className="bg-[#75975e] min-h-screen flex justify-center items-center">
        <ImSpinner2 size={36} className="spinner" />
      </div>
    );
  }

  return (
    <div className="md:min-h-[120vh] min-h-[130vh] bg-[#75975e] py-[15vh] flex flex-col gap-10 items-center justify-center">
      <h1 className="text-3xl font-bold ">Contact Us</h1>
      <form onSubmit={handleSubmit} className="flex flex-col md:w-96 w-72">
        <label className="text-lg" htmlFor="fullName">
          Full Name/Brand Name:
        </label>
        <input
          required
          type="text"
          id="fullName"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-[#75975e]"
        />
        <br />

        <label className="text-lg" htmlFor="email">
          Email:
        </label>
        <input
          required
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-[#75975e]"
        />

        <br />

        <label className="text-lg" htmlFor="subject">
          Subject:
        </label>
        <input
          required
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-[#75975e]"
        />

        <br />

        <label className="text-lg" htmlFor="message">
          Message:
        </label>
        <div>
          <textarea
            required
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="border-solid border-[1px] border-black rounded w-full h-56 p-4 bg-[#75975e]"
          />
        </div>

        <br />
        <button type="submit" className="green-button1">
          Submit
        </button>
      </form>
      <ScrollRestoration />
    </div>
  );
};
