import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { URL } from "../utilities";

type useOrderProps = {
  queryKey: string[];
  id?: string;
};

export const useOrder = ({ queryKey, id }: useOrderProps) => {
  const fetchOrder = () => {
    return axios.get(`${URL}/order/api/${id ? id : ""}`);
  };

  return useQuery({
    queryKey: queryKey,
    queryFn: fetchOrder,
    select: (data) => data.data,
  });
};
