import axios from "axios";
import { useEffect, useState } from "react";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
import { URL } from "../utilities";
import toast from "react-hot-toast";
import { useAuthStore } from "../hooks/useAuthStore";
import { useCartStore } from "../hooks/useCartStore";
import { ImSpinner2 } from "react-icons/im";

export const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { setUser } = useAuthStore();
  const { cart } = useCartStore();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const loadUser = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await axios.get(
          `${URL}/account/auth/users/me/`,
          config
        );
        setUser({
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          phone_number: response.data.phone_number,
          profile: response.data.profile,
        });
        navigate(`${cart.length > 0 ? "/Cart" : "/Account"}`);
      } catch {
        alert("Loading User Error");
      }
    } else {
      alert("No Access Token");
    }
  };

  const login = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email: formData.email,
      password: formData.password,
    });

    try {
      const response = await axios.post(
        `${URL}/account/auth/jwt/create/`,
        body,
        config
      );

      localStorage.setItem("access", response.data.access);

      loadUser();
      setLoading(false);
    } catch {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  return (
    <div className="py-32 bg-[#75975e] h-screen flex justify-center items-center">
      {loading ? (
        <ImSpinner2 size={36} className="spinner" />
      ) : (
        <div className="flex flex-col justify-center items-center gap-y-8">
          <h1 className="text-2xl font-bold">Sign Into Your Account</h1>
          <form onSubmit={login} className="flex flex-col md:w-96 w-72">
            <label className="text-lg" htmlFor="email">
              Email:
            </label>
            <input
              required
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />

            <label className="text-lg" htmlFor="password">
              Password:
            </label>
            <input
              required
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />
            <button type="submit" className="green-button1">
              Login
            </button>
          </form>
          <div>
            <p className="text-lg">
              Don't have an account?{" "}
              <Link to={"/Account/SignUp"} className="text-white text-base p-2">
                Sign Up
              </Link>
            </p>
            <p className="text-lg">
              Forgot your password?{" "}
              <Link
                to={"/Account/ResetPassword"}
                className="text-white text-base p-2"
              >
                Reset Password
              </Link>
            </p>
          </div>
        </div>
      )}
      <ScrollRestoration />
    </div>
  );
};
