import { ImSpinner2 } from "react-icons/im";
import { useProductDetail } from "../hooks/useProductDetail";
import { Link } from "react-router-dom";

type ItemHistoryCardProps = {
  item: string;
  quantity: string;
};

export const ItemHistoryCard = ({ item, quantity }: ItemHistoryCardProps) => {
  const { isLoading, isError, data } = useProductDetail({
    queryKey: ["product-detail", item],
    id: item,
  });

  if (!(item && quantity)) {
    return <div></div>;
  }

  if (isLoading) return <ImSpinner2 size={36} className="spinner" />;

  if (isError) return <h1>Something Went Wrong</h1>;

  return (
    <div className="flex justify-around items-center py-2">
      <Link
        to={`/Products/${item}`}
        className="hover:opacity-50 transition duration-500"
      >
        <img
          src={data?.image1}
          width={75}
          className="aspect-square object-cover rounded-xl w-20"
        />
      </Link>
      <h1 className="w-20 font-bold">{quantity}</h1>
    </div>
  );
};
