import { ScrollRestoration } from "react-router-dom";

export const About = () => {
  return (
    <div className="bg-[#75975e] h-screen flex justify-center items-center">
      About
      <ScrollRestoration />
    </div>
  );
};
