import { create } from "zustand";
import { ItemType } from "../utilities";
import { persist } from "zustand/middleware";
import toast from "react-hot-toast";

type CartType = {
  cart: ItemType[];
  addItem: (item: ItemType) => void;
  updateQuantity: (itemId: number, updateType: "plus" | "minus") => void;
  removeItem: (itemId: number) => void;
  clearCart: () => void;
};

export const useCartStore = create<CartType>()(
  persist(
    (set) => ({
      cart: [],
      addItem(item: ItemType) {
        set((state) => {
          const index = state.cart.findIndex(
            (cartItem: ItemType) => cartItem.id === item.id
          );
          if (index >= 0) {
            toast.error("You've Already Add The Item To Cart");
            return { cart: state.cart };
          } else {
            toast.success("Item Added To Cart");
            return { cart: [...state.cart, item] };
          }
        });
      },
      updateQuantity(itemId: number, updateType: "plus" | "minus") {
        set((state) => {
          const index = state.cart.findIndex(
            (cartItem: ItemType) => cartItem.id === itemId
          );
          if (index >= 0) {
            state.cart[index].quantity =
              updateType === "plus"
                ? state.cart[index].quantity + 1
                : state.cart[index].quantity - 1;
            if (state.cart[index].quantity < 1) {
              return {
                cart: state.cart.filter((item: ItemType) => item.id !== itemId),
              };
            }

            return { cart: state.cart };
          } else {
            return { cart: state.cart };
          }
        });
      },
      removeItem(itemId: number) {
        set((state) => {
          const updatedCart = state.cart.filter(
            (item: ItemType) => item.id !== itemId
          );
          return { cart: updatedCart };
        });
      },
      clearCart() {
        set(() => ({ cart: [] }));
      },
    }),
    {
      name: "cart",
      getStorage: () => localStorage,
    }
  )
);
