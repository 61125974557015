import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { URL } from "../utilities";

type useCategoryProps = {
  queryKey: string[];
};

export const useCategory = ({ queryKey }: useCategoryProps) => {
  const fetchCategory = () => {
    return axios.get(`${URL}/product/category/api`);
  };

  return useQuery({
    queryKey: queryKey,
    queryFn: fetchCategory,
    select: (data) => data.data,
  });
};
