import Marquee from "react-fast-marquee";
import IMG1 from "../static/images/IMG_1.png";
import IMG2 from "../static/images/IMG_2.png";
import IMG3 from "../static/images/IMG_3.png";
import IMG4 from "../static/images/IMG_4.png";
import IMG5 from "../static/images/IMG_5.png";
import IMG6 from "../static/images/IMG_6.png";
import IMG7 from "../static/images/IMG_7.png";
import IMG8 from "../static/images/IMG_8.png";
import IMG9 from "../static/images/IMG_9.png";
import IMG10 from "../static/images/IMG_10.png";
import IMG11 from "../static/images/IMG_11.png";
import IMG12 from "../static/images/IMG_12.png";
import IMG13 from "../static/images/IMG_13.png";
import IMG14 from "../static/images/IMG_14.png";
import IMG15 from "../static/images/IMG_15.png";

const GallerySection = () => {
  return (
    <div className="bg-[#D7E8B6] flex flex-col justify-center items-center gap-y-5 pb-20">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="relative bottom-[10px]"
      >
        <path
          fill="#755138"
          fill-opacity="1"
          d="M0,32L48,53.3C96,75,192,117,288,144C384,171,480,181,576,154.7C672,128,768,64,864,53.3C960,43,1056,85,1152,90.7C1248,96,1344,64,1392,48L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Marquee speed={40}>
        <div className="flex gap-x-5 pl-5 h-[10vh] hidden">
          <h1 className="w-screen text-4xl md:text-5xl text-[#38271a]">
            Title1
          </h1>
          <h1 className="w-screen text-4xl md:text-5xl text-[#38271a]">
            Title2
          </h1>
          <h1 className="w-screen text-4xl md:text-5xl text-[#38271a]">
            Title3
          </h1>
        </div>
      </Marquee>
      <Marquee speed={20}>
        <div className="flex pl-5">
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG6} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG7} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG8} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG9} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG10} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG11} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG12} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG13} alt="" className="object-cover" width={"100%"} />
          </div>
        </div>
      </Marquee>
      <Marquee speed={25}>
        <div className="flex pl-5">
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG1} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG2} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG3} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG4} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] mr-[20px] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG14} alt="" className="object-cover" width={"100%"} />
          </div>
          <div className="h-[30vh] aspect-[4/3] bg-[#6B4A33] flex justify-center align-center">
            <img src={IMG15} alt="" className="object-cover" width={"100%"} />
          </div>
        </div>
      </Marquee>
    </div>
  );
};

export default GallerySection;
