import { OrderHistoryCard } from "../components/OrderHistoryCard";
import { useAuthStore } from "../hooks/useAuthStore";
import { useOrder } from "../hooks/useOrder";
import { OrderType } from "../utilities";
import { TbTruckDelivery } from "react-icons/tb";
import { GiPresent } from "react-icons/gi";

export const OrderHistory = () => {
  const { user } = useAuthStore();
  const { isError, isLoading, data } = useOrder({ queryKey: ["order"] });

  return (
    <div className="py-48 bg-[#75975e] min-h-screen flex flex-col items-center justify-center gap-y-8">
      <h1 className="text-2xl text-white">Your Order History</h1>
      <div className="flex flex-col justify-center items-center gap-2">
        {isLoading ? (
          "Loading"
        ) : isError ? (
          "Something Went Wrong"
        ) : data?.length < 1 ? (
          <h2 className="text-white">No Orders</h2>
        ) : (
          data?.map((order: OrderType, index: number) => {
            if (order.customer === user.id)
              return (
                <div key={index} className="flex flex-col items-center">
                  {/* <div className="bg-[#edfad4] p-1 rounded-t-lg">
                    {order.status === "Delivered" ? (
                      <div>
                        <GiPresent className="nav-icons" />
                        <span className="nav-icons-text">{order.status}</span>
                      </div>
                    ) : (
                      <div>
                        <TbTruckDelivery className="nav-icons" />
                        <span className="nav-icons-text">{order.status}</span>
                      </div>
                    )}
                  </div> */}
                  <OrderHistoryCard
                    order_code={order.order_code}
                    order_date={order.order_date}
                    status={order.status}
                  />
                </div>
              );
          })
        )}
      </div>
    </div>
  );
};
