import { useEffect, useState } from "react";
import { useProduct } from "../hooks/useProduct";
import { CategoryType, ProductType } from "../utilities";
import { Link, ScrollRestoration } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";

export const CategoryCard = ({ category }: { category: CategoryType }) => {
  return (
    <div className="flex flex-col justify-center items-center hover:scale-110 hover:cursor-pointer transition duration-300">
      <div className="bg-[#402c1f] px-4 rounded-t-xl">
        <h1 className="text-[#E5D3B3]">{category.name.toUpperCase()}</h1>
      </div>
      <Link
        to={"/Products"}
        state={{ searchQuery: category.name, isSearch: false }}
        className="h-72 w-80 bg-[#a3896c] rounded-xl overflow-hidden flex flex-col justify-center items-center gap-y-4"
      >
        <div className="flex justify-center items-center gap-x-4">
          <div className="w-32 h-28 flex flex-col  rounded-xl bg-[#402c1f]">
            <img
              src={category.image1}
              className="h-full w-full object-cover rounded-xl"
            />
          </div>
          <div className="w-32 h-28 flex flex-col justify-center items-center rounded-xl bg-[#402c1f]">
            <img
              src={category.image2}
              className="h-full w-full object-cover rounded-xl"
            />
          </div>
        </div>
        <div className="flex justify-center items-center  gap-x-4">
          <div className="w-32 h-28 flex flex-col justify-center items-center rounded-xl bg-[#402c1f]">
            <img
              src={category.image3}
              className="h-full w-full object-cover rounded-xl"
            />
          </div>
          <div className="w-32 h-28 flex flex-col justify-center items-center rounded-xl bg-[#402c1f]">
            <img
              src={category.image4}
              className="h-full w-full object-cover rounded-xl"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
