export type ProductType = {
  id: number;
  image1: string;
  image2: string;
  image3: string;
  name: string;
  category: string;
  description: string;
  price: number;
};

export type CategoryType = {
  name: string;
  order_number: number;
  image1: string;
  image2: string;
  image3: string;
  image4: string;
};

export type ItemType = {
  id: number;
  image: string;
  name: string;
  price: number;
  quantity: number;
};

export type UserType = {
  id: number | null;
  name: string;
  email: string;
  phone_number: string;
  profile: string;
};

export type OrderType = {
  order_code: string;
  order_date: string;
  items: string;
  quantity: string;
  customer: number;
  status: "On It's Way" | "Delivered";
  total_price: number;
};

export function dynamicSort(property: any) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export const shuffle = (array: any[]) => {
  for (let i = array?.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const URL = "https://keatibebat.com";
