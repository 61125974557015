import { ScrollRestoration } from "react-router-dom";

export const PrivacyPolicy = () => {
  return (
    <div className="px-10 py-[15vh] min-h-screen bg-[#75975e] flex flex-col gap-y-4">
      <div className="flex justify-center mt-4">
        <h2 className="mt-4 font-bold text-3xl">
          <u>PRIVACY POLICY</u>
        </h2>
      </div>

      <p>
        <strong>App Privacy Policy</strong>
      </p>
      <p>Our Privacy Policy was last updated on Aug 18, 2024.</p>
      <p>
        This Privacy Policy describes the policies and practices of Kea-Tibebat
        PLC ("we," "us," or "our") regarding the collection, use, and disclosure
        of information when you use our mobile application (the "App").
      </p>
      <p>
        By using the App, you consent to the practices described in this Privacy
        Policy.
      </p>
      <ol>
        <li>
          <b>Information We Collect</b>
        </li>

        <ul>
          <li>
            <b>1.1 Information You Provide</b>

            <p>
              We may collect and store information that you voluntarily provide
              when using the website, such as:
            </p>

            <p>
              {" "}
              - Contact Information: When you create an account, we may collect
              your email address and contact name.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <b>1.2 Automatically Collected Information</b>

            <p>
              No information is automaticaly collected while users are using the
              app:
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <b>1.3 Cookies and Tracking Technologies</b>
          </li>

          <p>
            We may use cookies and similar tracking technologies to collect
            information about your interactions with the App.
          </p>
        </ul>

        <li>
          <b>How We Use Your Information</b>
        </li>
        <ul>
          <li>
            <p>
              We don't share your information with third parties for any reason.
            </p>
            <p>We use the information we collect for the following purposes:</p>

            <p>
              {" "}
              - To provide and improve our services, including personalizing
              your experience.
            </p>

            <p> - To respond to your inquiries, comments, or requests.</p>

            <p>
              {" "}
              - To communicate with you, including sending notifications and
              updates.
            </p>
          </li>
        </ul>

        <li>
          <strong>How We Share Your Information</strong>
        </li>
        <ul>
          <li>
            <p>
              We don't share your information with third parties for any reason.
            </p>
          </li>
        </ul>

        <li>
          <strong>Your Choices</strong>
        </li>

        <ul>
          <li>
            <p>
              You may have certain rights and choices regarding the information
              we collect and use, including:
            </p>

            <p>
              {" "}
              - Accessing, correcting, or deleting your personal information.
            </p>

            <p> - Opting out of certain data collection and use.</p>

            <p> - Managing your notification and communication preferences</p>
          </li>
        </ul>

        <li>
          <strong>Security</strong>
        </li>

        <ul>
          <li>
            <p>
              We take reasonable measures to protect your information from
              unauthorized access or disclosure.
            </p>
          </li>
        </ul>

        <li>
          <strong>Changes to this Privacy Policy</strong>
        </li>

        <ul>
          <li>
            <p>
              We may update this Privacy Policy from time to time, and any
              changes will be posted in the App.
            </p>
            <p>
              Your continued use of the App after such changes constitutes your
              acceptance of the updated Privacy Policy.
            </p>
          </li>
        </ul>

        <li>
          <strong>Contact Us</strong>
        </li>

        <ul>
          <li>
            By visiting this page on our website:{" "}
            <a href="https://keatibebat.com">https://keatibebat.com</a>
          </li>
          <li>
            By sending us an email:{" "}
            <a href="mailto:keatibebat@gmail.com">keatibebat@gmail.com</a>
          </li>
        </ul>
      </ol>
      <ScrollRestoration />
    </div>
  );
};
