import axios from "axios";
import { useState } from "react";
import { ScrollRestoration, useNavigate, useParams } from "react-router-dom";
import { URL } from "../utilities";
import toast from "react-hot-toast";
import { ImSpinner2 } from "react-icons/im";

export const ResetPasswordConfirm = () => {
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const { uid, token }: any = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (!(uid && token)) alert("No UID and TOKEN");

    const body = JSON.stringify({
      uid: uid,
      token: token,
      new_password: formData.new_password,
      re_new_password: formData.re_new_password,
    });

    try {
      await axios.post(
        `${URL}/account/auth/users/reset_password_confirm/`,
        body,
        config
      );
      toast.success("Password Updated Successfully");
      setLoading(false);
      navigate("/Account/Login");
    } catch {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  return (
    <div className="py-32 bg-[#75975e] min-h-screen flex justify-center items-center">
      {loading ? (
        <ImSpinner2 size={36} className="spinner" />
      ) : (
        <div className="flex flex-col justify-center items-center gap-y-8">
          <h1 className="text-2xl font-bold">Reset Password</h1>
          <form onSubmit={handleSubmit} className="flex flex-col md:w-96 w-72">
            <label className="text-lg" htmlFor="new_password">
              New Password:
            </label>
            <input
              required
              type="password"
              id="new_password"
              name="new_password"
              value={formData.new_password}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />

            <label className="text-lg" htmlFor="re_new_password">
              Password:
            </label>
            <input
              required
              type="password"
              id="re_new_password"
              name="re_new_password"
              value={formData.re_new_password}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />
            <button type="submit" className="green-button1">
              Confirm
            </button>
          </form>
        </div>
      )}
      <ScrollRestoration />
    </div>
  );
};
