import axios from "axios";
import { useState } from "react";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
import { URL } from "../utilities";
import toast from "react-hot-toast";
import { ImSpinner2 } from "react-icons/im";
import { Console } from "console";

export const SignUpForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
    re_password: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const signUp = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      name: formData.name,
      email: formData.email,
      phone_number: formData.phone_number,
      password: formData.password,
      re_password: formData.re_password,
    });

    try {
      await axios.post(`${URL}/account/auth/users/`, body, config);
      toast.success("Check Your Email To Activate Your Account");
      setLoading(false);
      navigate("/Account/Login");
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Something Went Wrong");
    }
  };

  return (
    <div className="py-32 bg-[#75975e] min-h-screen flex flex-col justify-center items-center gap-y-8">
      {loading ? (
        <ImSpinner2 size={36} className="spinner" />
      ) : (
        <div className="flex flex-col justify-center items-center gap-y-8">
          <h1 className="text-2xl font-bold">Become Our Member</h1>
          <form onSubmit={signUp} className="flex flex-col md:w-96 w-72">
            <label className="text-lg" htmlFor="name">
              Name:
            </label>
            <input
              required
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />

            <label className="text-lg" htmlFor="email">
              Email:
            </label>
            <input
              required
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />
            <label className="text-lg" htmlFor="phone_number">
              Phone Number (Optional):
            </label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />

            <label className="text-lg" htmlFor="password">
              Password:
            </label>
            <input
              required
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />
            <label className="text-lg" htmlFor="re_password">
              Retype Password:
            </label>
            <input
              required
              type="password"
              id="re_password"
              name="re_password"
              value={formData.re_password}
              onChange={handleChange}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />
            <button type="submit" className="green-button1">
              Sign Up
            </button>
          </form>
          <div>
            <p className="text-lg">
              Already have an account?{" "}
              <Link to={"/Account/Login"} className="text-white text-base p-2">
                Login
              </Link>
            </p>
          </div>
        </div>
      )}
      <ScrollRestoration />
    </div>
  );
};
