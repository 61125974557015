import axios from "axios";
import { useState } from "react";
import { URL } from "../utilities";
import toast from "react-hot-toast";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email: email });
    console.log(`${URL}/account/auth/users/reset_password/`);
    try {
      await axios.post(
        `${URL}/account/auth/users/reset_password/`,
        body,
        config
      );
      toast.success("Check Your Email");
      setLoading(false);
    } catch (err) {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  return (
    <div className="py-32 bg-[#75975e] min-h-screen flex justify-center items-center gap-y-8">
      {loading ? (
        <ImSpinner2 size={36} className="spinner" />
      ) : (
        <div className="flex flex-col justify-center items-center gap-y-8">
          <h1 className="text-2xl font-bold">Reset Password</h1>
          <form onSubmit={handleSubmit} className="flex flex-col md:w-96 w-72">
            <label className="text-lg" htmlFor="email">
              Email:
            </label>
            <input
              required
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-lg border-solid border-[1px] border-black rounded h-10 px-4 bg-transparent"
            />

            <br />

            <button type="submit" className="green-button1">
              Confirm
            </button>
          </form>
        </div>
      )}
      <ScrollRestoration />
    </div>
  );
};
