import { useEffect } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import "../static/css/Pages/detail.css";
import {
  ScrollRestoration,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { Recommended } from "../components/Recommended";
import { useCartStore } from "../hooks/useCartStore";
import { TiShoppingCart } from "react-icons/ti";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { useProductDetail } from "../hooks/useProductDetail";

export const ProductDetail = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { addItem } = useCartStore();

  const { isLoading, isError, data } = useProductDetail({
    queryKey: ["product-detail", id ? id : ""],
    id: id ? id : "",
  });

  var thumbnail = document.getElementsByClassName("thumbnail");
  var activeImage = document.getElementsByClassName("activeImage");
  var activeImageIndex = 0;

  const handelClick = (direction: "next" | "previous") => {
    if (direction === "next") {
      activeImageIndex++;

      if (activeImageIndex >= thumbnail.length) {
        activeImageIndex = thumbnail.length - 1;
        return;
      }

      for (var i = 0; i < thumbnail.length; i++) {
        if (activeImage.length > 0) {
          activeImage[0].classList.remove("activeImage");
        }

        thumbnail[activeImageIndex].classList.add("activeImage");

        (document.getElementById("featured") as HTMLImageElement).src = (
          thumbnail[activeImageIndex] as HTMLImageElement
        ).src;
      }
    } else {
      activeImageIndex--;

      if (activeImageIndex <= -1) {
        activeImageIndex = 0;
        return;
      }

      for (var i = 0; i < thumbnail.length; i++) {
        if (activeImage.length > 0) {
          activeImage[0].classList.remove("activeImage");
        }

        thumbnail[activeImageIndex].classList.add("activeImage");

        (document.getElementById("featured") as HTMLImageElement).src = (
          thumbnail[activeImageIndex] as HTMLImageElement
        ).src;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") handelClick("previous");
      else if (e.key === "ArrowRight") handelClick("next");
    });

    for (var i = 0; i < thumbnail.length; i++) {
      thumbnail[i].addEventListener("click", (e) => {
        if (activeImage.length > 0) {
          activeImage[0].classList.remove("activeImage");
        }

        (e.currentTarget as HTMLElement).classList.add("activeImage");

        (document.getElementById("featured") as HTMLImageElement).src = (
          e.currentTarget as HTMLImageElement
        ).src;
      });
    }
  });

  if (isLoading) {
    return (
      <div className="bg-[#755138] min-h-screen flex justify-center items-center">
        <ImSpinner2 size={36} className="spinner" />
        <ScrollRestoration />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-screen bg-[#755138] flex justify-center items-center">
        Something went wrong
        <ScrollRestoration />
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-[#755138] justify-center align-center">
      <div className="flex justify-center items-center boxes px-28 pt-[150px] gap-10">
        <div className="flex md:h-auto">
          <div className="flex flex-col gap-y-8 md:gap-y-6">
            <div
              className={`justify-center items-center gap-x-8 ${
                data.image2 ? "flex" : "hidden"
              }`}
            ></div>
            <div className="flex justify-center gap-x-4">
              {data.image2 ? (
                <div
                  className="flex items-center gap-x-1 hover:cursor-pointer"
                  onClick={() => handelClick("previous")}
                >
                  <div className="border-solid border-2 border-[#38271a] rounded-[50%] p-2 hover:bg-[#38271a] hover:text-white text-[#38271a] flex justify-center items-center">
                    <MdArrowBackIosNew scale={36} />
                  </div>
                </div>
              ) : null}
              <div className="flex flex-col items-center gap-y-4">
                <div className="flex flex-col items-center">
                  <h1 className="font-bold text-xl md:text-3xl text-[#38271a] whitespace-nowrap">
                    {data.name?.toUpperCase()}
                  </h1>
                  <p className="p-4 text-lg text-[#38271a] font-bold">
                    Price: ${data.price}
                  </p>
                </div>
                <img
                  id="featured"
                  className=" md:w-[600px]"
                  src={data.image1}
                />
              </div>
              {data.image2 ? (
                <div
                  className="flex items-center gap-x-1 hover:cursor-pointer"
                  onClick={() => handelClick("next")}
                >
                  <div className="border-solid border-2 border-[#38271a] rounded-[50%] p-2 hover:bg-[#38271a] hover:text-white text-[#38271a] flex justify-center items-center">
                    <MdArrowForwardIos scale={36} />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex justify-center">
              <img
                className="thumbnail activeImage hover:cursor-pointer"
                src={data.image1}
              />
              {data.image2 ? (
                <img
                  className="thumbnail hover:cursor-pointer"
                  src={data.image2}
                />
              ) : null}
              {data.image3 ? (
                <img
                  className="thumbnail hover:cursor-pointer"
                  src={data.image3}
                />
              ) : null}
            </div>
            <div className="flex justify-center items-center gap-x-4 h-12 scale-110">
              <button
                className="brown-button"
                onClick={() => {
                  addItem({
                    id: data.id,
                    image: data.image1,
                    name: data.name,
                    price: data.price,
                    quantity: 1,
                  });
                  navigate("/Cart");
                }}
              >
                <FaMoneyCheckDollar className="nav-icons" />
                <p className="nav-icons-text">Buy</p>
              </button>
              <button
                className="brown-button"
                onClick={() => {
                  addItem({
                    id: data.id,
                    image: data.image1,
                    name: data.name,
                    price: data.price,
                    quantity: 1,
                  });
                }}
              >
                <TiShoppingCart className="nav-icons" />
                <p className="nav-icons-text">Add To Cart</p>
              </button>
            </div>
          </div>
        </div>
        <div className={`${data.description ? "block" : "hidden"}`}>
          <p className={`text-lg `}>{data.description}</p>
        </div>
        <ScrollRestoration />
      </div>

      <Recommended category={data.category} image={data.image1} />
    </div>
  );
};
