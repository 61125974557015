import { Link } from "react-router-dom";
import { ProductType } from "../utilities";

const ProductCard = ({ product }: { product: ProductType }) => {
  return (
    <Link to={`/Products/${product.id}`}>
      <div className="bg-[#a3896c] hover:scale-[103%] max-w-[260px] transition duration-300 hover:cursor-pointer overflow-hidden card flex flex-col medium-proudct-card rounded aspect-[9/16]">
        <img
          src={product.image1}
          className="w-full h-[80%] md:h-[85%] object-cover"
          loading="lazy"
        />
        <div className="px-2 h-[20%] md:h-[15%] flex flex-col justify-center">
          <p className="text-white text">Price: ${product.price}</p>
          <p className="text-white text">
            {product.name?.substring(0, 9)}
            {product.name?.length > 9 ? "..." : ""}
          </p>
          <p className="text-white text">{product.id}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
