import { ScrollRestoration } from "react-router-dom";
import GallerySection from "../components/GallerySection";
import LandingPage from "../components/LandingPage";
import ProductSection from "../components/ProductSection";

export const Home = () => {
  return (
    <>
      <LandingPage />
      <ProductSection />
      <GallerySection />
      <ScrollRestoration />
    </>
  );
};
